<template>
  <div
    style="display: grid; grid-template-rows: auto auto 1fr"
    class="container-xs"
  >
    <AppHeader back-to="/profile/payments" />
    <p class="app-subtitle" style="margin-top: 2rem">ADD A NEW CARD</p>
    <AddCardForm
      ref="form"
      style="margin-top: 1rem"
      @valid="value => (isValid = value)"
      @add-card-start="onAddCardStart"
      @add-card-success="onAddCardSuccess"
      @add-card-error="onAddCardError"
      @add-card-end="onAddCardEnd"
    />
    <XButton
      id="addCard"
      type="large"
      style="margin-top: 3rem"
      :disabled="!isValid"
      @click="$refs.form.addCard()"
      >SAVE</XButton
    >
    <LoadingModalFullScreen
      ref="loadingModal"
      :text="$t('payment-methods.adding-payment-modal')"
      non-closable
    />
    <InfoModal
      ref="successModal"
      title="SUCCESS!"
      text="Your card has been added to your payment methods."
      cta="CLOSE"
      hide-close-button
      @cta="$refs.successModal.hide()"
      @hide="$router.push('/settings')"
    />
    <InfoModal
      ref="infoModal"
      title="Error"
      text="Something went wrong"
      cta="try again"
      @cta="$refs.infoModal.hide()"
    />
  </div>
</template>

<script>
import AppHeader from '../../components-rf/AppHeader.vue'
import AddCardForm from '../../components-rf/forms/AddCardForm.vue'
import InfoModal from '../../components-rf/modals/InfoModal.vue'
import LoadingModalFullScreen from '../../components-rf/modals/LoadingModalFullScreen.vue'
import XButton from '../../components/common/global/XButton.vue'

export default {
  name: 'AddNewCard',
  components: {
    AddCardForm,
    LoadingModalFullScreen,
    InfoModal,
    XButton,
    AppHeader,
  },
  data() {
    return { isValid: false }
  },
  methods: {
    onAddCardStart() {
      this.$refs.loadingModal.show()
    },
    onAddCardSuccess() {
      this.$refs.successModal.show()
    },
    onAddCardError() {
      this.$refs.errorModal.show()
    },
    onAddCardEnd() {
      this.$refs.loadingModal.hide()
    },
  },
}
</script>

<style scoped lang="scss">
.x-label {
  margin-top: 1.5rem;
  color: var(--color-grey);
  text-transform: uppercase;
}
.base-card-element {
  border-bottom: 1px solid grey;
  padding: 0.5rem;
}
</style>
